import React, { useState, useRef } from "react"
import cx from "classnames"
import { withOrientationChange } from "react-device-detect"
import carret from "@/public/icons/arrow-up.svg"
import { KEYCODE } from "@/constants/index"
import { getAnalyticsData } from "@/components/ProductList/v2/Sort/sortAnalytics"
import useOnClickOutside from "@/hooks/useOnClickOutside"

const Sort = ({
  texts: {
    sort = "",
    selected = "",
    sortByAria = "",
    altSort = "",
    sortbyExpanded = "",
    sortbyClosed = "",
  },
  data,
  onChange,
  page,
  selectedtab,
  tabIndex = 0,
  isSearch,
  selectedSort,
  isMyFavoriteTab = false,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const sortClass = cx({ sort: true, "sort--open": isOpen })
  const sortBtn = useRef(null)
  const pastServiceMenu = useRef(null)
  const sortByWrapper = useRef(null)

  useOnClickOutside(sortByWrapper, () => {
    isOpen && setIsOpen(false)
  })

  const btnKeyDown = e => {
    switch (e.keyCode) {
      case KEYCODE.ENTER:
        e.preventDefault()
        !isOpen ? pastServiceMenu.current.firstElementChild.focus() : ""
        setIsOpen(!isOpen)
        break
      default:
        break
    }
  }

  const handleKeyDown = (i, e) => {
    e.preventDefault()

    const ele = pastServiceMenu.current.childNodes
    switch (e.keyCode) {
      case KEYCODE.ESC:
        setIsOpen(false)
        break
      case KEYCODE.UP:
        if (i > 0) ele[i - 1].focus()
        break
      case KEYCODE.DOWN:
        if (i < data.length - 1) ele[i + 1].focus()
        break
      case KEYCODE.SPACE:
      case KEYCODE.ENTER:
        ele[i].click()
        sortBtn.current.focus()
        break
      default:
        break
    }
  }
  return (
    <div className={sortClass} ref={sortByWrapper} aria-description={sort}>
      <button
        ref={sortBtn}
        className="sort__toggle"
        tabIndex={tabIndex}
        role="combobox"
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={btnKeyDown}
      >
        <div className="sort-by-text">{sort} </div>
        <div className="sort-options">
          {` ${isMyFavoriteTab ? "" : ":"} ${
            selectedSort ? selectedSort.name : "Featured"
          }`}
        </div>
        <img
          src={carret?.src}
          alt={altSort}
          className="sort__toggle-icon"
          aria-hidden="true"
        />
      </button>
      <ul
        ref={pastServiceMenu}
        className="sort__drop-down"
        aria-label={isOpen ? sortbyExpanded : sortbyClosed}
        aria-hidden={!isOpen}
      >
        {data.map((item, i) => {
          return (
            <li
              key={i}
              id={`select-options-${i}`}
              role="option"
              tabIndex="-1"
              aria-selected={item?.name === selectedSort?.name}
              className={`sort__item gbh-data-layer ${
                (item && item.name) === (selectedSort && selectedSort.name)
                  ? "active"
                  : ""
              }`}
              data-gbh-data-layer={getAnalyticsData(item, null, {
                selectedtab,
                page,
              })}
              onClick={() => {
                onChange(item)
                setIsOpen(false)
              }}
              onKeyDown={e => handleKeyDown(i, e)}
            >
              {item.name}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default withOrientationChange(Sort)
